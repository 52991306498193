<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'passport-head' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Добавление члена руководящего органа</h1>
    </div>
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="lastname">Фамилия<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.lastname.$error }">
              <input
                id="lastname"
                type="text"
                class="form-control__input"
                name="lastname"
                placeholder="Иванов"
                v-model.trim="form.lastname"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="firstname">Имя<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.firstname.$error }">
              <input
                id="firstname"
                type="text"
                class="form-control__input"
                name="firstname"
                placeholder="Иван"
                v-model.trim="form.firstname"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="patronymic">Отчество</label>
            <div class="form-control">
              <input
                id="patronymic"
                type="text"
                class="form-control__input"
                name="patronymic"
                placeholder="Иванович"
                v-model.trim="form.patronymic"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="post">Должность<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.post.$error }">
              <input
                id="post"
                type="text"
                class="form-control__input"
                name="post"
                placeholder="Введите должность"
                v-model.trim="form.post"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.post.$dirty && !$v.form.post.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label>Образование</label>
            <div class="form-table">
              <div class="form-table__head">
                <div class="form-table__item">Год окончания обучения</div>
                <div class="form-table__item">Учебное заведение</div>
                <div class="form-table__item">Специальность</div>
              </div>
              <div
                v-for="(item, index) in form.education"
                :key="index"
                class="form-table__row"
              >
                <button v-if="index > 0" @click="onDeleteItem(index, 'education')" class="link link--absolute" type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
                <div class="form-table__item">
                  <masked-input
                    v-model="item.year"
                    name="date_election"
                    mask="1111"
                    :id="`year${index}`"
                    autocomplete="off"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`edu_place${index}`"
                    type="text"
                    :name="`edu_place${index}`"
                    v-model.trim="item.edu_place"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`spec${index}`"
                    type="text"
                    :name="`spec${index}`"
                    v-model.trim="item.spec"
                  />
                </div>
              </div>
            </div>
            <button @click="onCloneItem('education', { year: '', edu_place: '', spec: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
          <div class="form-group">
            <label>Ученая степень, звание</label>
            <div class="form-group form-group--minindent" v-for="(item, index) in form.academic" :key="index">
              <div class="form-control">
                <input
                  :id="`edu_rank${index}`"
                  type="text"
                  class="form-control__input"
                  :name="`edu_rank${index}`"
                  v-model.trim="item.edu_rank"
                />
                <button v-if="index > 0" @click="onDeleteItem(index, 'academic')" class="link link--absolute" type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
              </div>
            </div>
            <button @click="onCloneItem('academic', { edu_rank: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="work_place">Место работы</label>
            <div class="form-control">
              <textarea
                id="work_place"
                name="work_place"
                class="form-control__textarea"
                v-model.trim="form.work_place"
                placeholder="Введите место работы"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="work_post">Должность на месте работы</label>
            <div class="form-control">
              <textarea
                id="work_post"
                name="work_post"
                class="form-control__textarea"
                v-model.trim="form.work_post"
                placeholder="Введите должность"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="date_election">Дата избрания в состав ПДРО РО РСМ</label>
            <div class="form-control" >
              <masked-input
                v-model="form.date_election"
                name="date_election"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_election"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Телефон</label>
            <vue-tel-input
              v-model="phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !is_phone_valid }"
              @validate="onValidatePhone"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z" fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }">
          <span>Добавить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { omit } from 'lodash'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'PassportHeadCreate',
  mixins: [ validationMixin ],
  components: {
    MaskedInput,
    VueTelInput,
  },
  validations: {
    form: {
      lastname: { required },
      firstname: { required },
      post: { required },
      email: { email },
    },
  },
  data() {
    return {
      form: {
        education: [
          {
            year: '',
            edu_place: '',
            spec: ''
          }
        ],
        academic: [
          {
            edu_rank: ''
          }
        ]
      },
      loading: false,
      phone_value: '',
      is_phone_valid: true,
      phone_picker_options: {
        placeholder: 'Введите номер телефона'
      },
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
  },
  mounted() {
    if (this.userRegion) this.form.region_org = this.userRegion
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      if (!this.form.date_election) {
        this.form = omit(this.form, ['date_election'])
      }
      this.$store.dispatch('passport/POST_HEAD_DATA', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'passport-head' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Руководящий орган добавлен.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка добавления.'
          });
        });
    },
    onCloneItem(name, obj) {
      this.form[name].push(obj)
    },
    onDeleteItem(index, name) {
      this.form[name].splice(index, 1);
    },
    onValidatePhone(number) {
      this.is_phone_valid = number.valid
      if (number.valid && number.number !== '') {
        this.form.phone = number.number
      } else {
        this.form.phone = ''
      }
      if (!number.hasOwnProperty('number')) {
        this.is_phone_valid = true
      }
    },
  }
}
</script>
